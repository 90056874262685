<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/OrderConfig/invoiceConfig"
            >发票管理 /
          </router-link>
          <span class="crumbs_item crumbs_last">发票详情</span>
        </span>
      </div>
      <div class="sider_right">
        <a-button type="primary" v-if="tableData.status == 1 && !tableData.kprq" @click="supplementInvoice" v-hasPermi="['invoice:supplement:update']">补开发票</a-button>
      </div>
    </div>
    <p class="headline">基础信息</p>
    <div class="bgDiv">
      <div class="detaillayoutbetween">
        <p class="type" v-if="tableData.subOrderCode">
          订单编号：{{ tableData.subOrderCode }}
        </p>
        <p class="type" v-if="tableData.userType">
          用户类型：{{ tableData.userType }}
        </p>
        <p class="type" v-if="tableData.paymentTime">
          支付时间：{{ tableData.paymentTime }}
        </p>
        <p class="type" v-if="tableData.payMethod">
          支付方式：{{ tableData.payMethod }}
        </p>
        <p class="type" v-if="tableData.tradeId">
          支付交易编号：{{ tableData.tradeId }}
        </p>
        <p class="type" v-if="tableData.userName">
          姓名：{{ tableData.userName }}
        </p>
        <p class="type" v-if="tableData.phone">手机号：{{ tableData.phone ? $encryption('user:infomation:sensitivequery', 'phone', tableData.phone) : '-' }}</p>
      </div>
    </div>
    <p class="headline">发票信息</p>
    <div class="bgDiv">
      <div class="detaillayoutbetween">
        <p class="type" v-if="tableData.fphm">发票号：{{ tableData.fphm }}</p>
        <p class="type" v-if="tableData.applyTime">
          申请发票时间：{{ tableData.applyTime }}
        </p>
        <p class="type" v-if="tableData.kprq">
          开票日期：{{ tableData.kprq }}
        </p>
        <p class="type" v-if="tableData.price">
          开票金额：{{ tableData.price }}
        </p>
        <p class="type" v-if="tableData.statusTxt">
          开票状态：{{ tableData.statusTxt }}
        </p>
        <p class="type" v-if="tableData.productType">
          商品类型：{{ tableData.productType }}
        </p>
        <p class="type" v-if="tableData.title">
          发票抬头：{{ tableData.title }}
        </p>
        <p class="type" v-if="tableData.taxNo">税号：{{ tableData.taxNo }}</p>

        <p class="type" v-if="tableData.email">邮箱：{{ tableData.email }}</p>
        <p class="type" v-if="tableData.openBank">
          开户行：{{ tableData.openBank }}
        </p>
        <p class="type" v-if="tableData.bankNo">
          银行账号：{{ tableData.bankNo }}
        </p>
        <p class="type" v-if="tableData.companyAddress">
          公司地址：{{ tableData.companyAddress }}
        </p>
        <p class="type" v-if="tableData.companyPhone">
          公司电话：{{ tableData.companyPhone }}
        </p>

        <p class="type" v-if="tableData.payee">收款人：{{ tableData.payee }}</p>
        <p class="type" v-if="tableData.drawer">
          开票人：{{ tableData.drawer }}
        </p>
        <p class="type" v-if="tableData.review">复核：{{ tableData.review }}</p>
        <div class="inputmessage">
          <p>备注：</p>
          <a-input placeholder="请输入备注" v-model="tableData.remark" />
        </div>
      </div>
    </div>
    <p class="errorInfo" v-if="tableData.errorInfo">发票开票提示信息：<span>{{ tableData.errorInfo }}</span></p>
    <p class="headline">商品信息</p>
    <a-table
      class="table-template"
      :rowKey="(item) => item.id"
      :columns="columns"
      :data-source="tableData.productList"
      :scroll="{ x: true }"
      @change="onPage"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ index + 1 }}
      </template>
      <template slot="amount" slot-scope="item, row">
        {{ "￥" + row.amount.toFixed(2) }}
      </template>
      <template slot="taxRate" slot-scope="item, row">
        {{ row.taxRate * 100 }}%
      </template>
      <template slot="taxAmount" slot-scope="item, row">
        {{ "￥" + row.taxAmount.toFixed(2) }}
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "单位",
    align: "center",
    dataIndex: "productUnit",
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "productCount",
  },
  {
    title: "金额",
    align: "center",
    dataIndex: "amount",
    scopedSlots: {
      customRender: "amount",
    },
  },
  {
    title: "税率",
    align: "center",
    dataIndex: "taxRate",
    scopedSlots: {
      customRender: "taxRate",
    },
  },
  {
    title: "税额",
    align: "center",
    dataIndex: "taxAmount",
    scopedSlots: {
      customRender: "taxAmount",
    },
  },
];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log(selected, selectedRows);
  },
};
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      remarks: "", // 备注
      columns,
      tableData: {}, // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      rowSelection, // 全选
      isChecked: false,
      selectedRowKeys: [], //选择的id
      visible: false,
      confirmLoading: false,
    };
  },
  // 事件处理器
  methods: {
    // 补开发票
    supplementInvoice() {
      let _this = this;
      this.$confirm({
        title: `确定补开发票吗?`,
        okText: "确认",
        onOk() {
          _this.$message.loading({ content: "正在处理...", duration: 0 });
          _this
            .$ajax({
              url: "/hxclass-management/invoice/build?invoiceRecordId=" + _this.invoiceRecordId,
              method: "post"
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.getdata();
                _this.$message.success("操作成功");
              }else{
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    //全选
    onChange(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        let id = this.tableData.map((item) => {
          return item.id;
        });
        this.selectedRowKeys = id;
      } else {
        this.isChecked = false;
        this.selectedRowKeys = [];
      }
      // console.log(`checked = ${e.target.checked}`);
    },
    select(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.selectedRowKeys.length == this.tableData.length) {
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      // console.log(selectedRowKeys);
    },
    selectAll(selectedRows) {
      this.selectedRows = selectedRows;
    },
    getdata() {
      this.$ajax({
        url: "/hxclass-management/invoice/" + this.invoiceRecordId,
        method: "get",
        params: {
          // invoiceRecordId: this.invoiceRecordId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.invoiceRecordId = this.$route.query.id;
    this.getdata();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-table-footer {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
    p {
      font-size: 12px;
      color: #333333;
      line-height: 22px;
      margin-left: 20px;
    }
    p:last-child {
      margin-left: 40px;
    }
    .price {
      color: rgba(255, 78, 0, 1);
      font-weight: bold;
    }
  }
}
/deep/.input {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
}
.header-box {
  border: 0;
}
.headline {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.bgDiv {
  background: #f6f6fc;
  border-radius: 5px;
  padding: 16px 20px;
  .detaillayoutbetween {
    // justify-content: space-between;
    .type {
      width: 400px;
    }
    .type:last-child {
      margin: 0;
    }
    .inputmessage {
      display: flex;
      p {
        width: 50px;
      }
      /deep/.ant-input {
        width: 300px;
      }
    }
  }
  .detaillayout,
  .detaillayoutbetween {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    color: #333333;
    line-height: 14px;
    .message {
      margin-left: 20px;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .message:nth-child(2) {
      margin-left: 5px;
    }
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    .type {
      display: block;
      width: 33%;
      margin-bottom: 16px;
      font-size: 12px;
      color: #333333;
      line-height: 14px;
    }
    .value {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
      line-height: 14px;
      margin-left: 5px;
    }
    .function {
      font-size: 12px;
      color: #2b6ed4;
      line-height: 14px;
      margin-left: 32px;
      cursor: pointer;
    }
  }
  .detaillayout:last-child {
    margin-bottom: 0;
  }
}
.errorInfo{
  padding: 20px 0;
  font-weight: bold;
  span{
    color: red;
  }
}
</style>
